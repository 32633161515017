
import {
  defineComponent,
  defineAsyncComponent,
  computed,
  ComputedRef,
} from 'vue';
import { useRoute } from 'vue-router';

const PasswordLogin = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "PasswordLogin" */ '@/components/login/PasswordLogin.vue'
  ),
);

enum SigninProviders {
  Geotab = 'geotab',
}

const loginUIs: Record<SigninProviders, any> = {
  geotab: defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "GeotabLogin" */ '@/components/login/GeotabLogin.vue'
    ),
  ),
};

export default defineComponent({
  name: 'LoginView',

  setup() {
    const route = useRoute();

    const signinProvider: ComputedRef<SigninProviders> = computed(
      () => route.query.signin_provider as SigninProviders,
    );

    const loginUI = computed(() =>
      signinProvider.value
        ? loginUIs[signinProvider.value] ?? PasswordLogin
        : PasswordLogin,
    );

    return {
      loginUI,
    };
  },
});
