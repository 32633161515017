import {LoginResponse, SingleUserDTO, UserGroups, UserValidationResponse} from '@/api/dto/user';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '.';
import { fetchSingleUser, refreshAccessToken } from '@/api/accidentplan-api';
import { ScopedStorage } from '@/helpers/scoped-localstorage';
import { alertError } from '@/helpers/alert-error';

/**
 * Enum for the current user's possible authentication states.
 * @readonly
 * @enum {string}
 */
export enum AuthStates {
  /** If login action has been triggered and we are logging in. */
  LOGGING_IN = 'LOGGING_IN',
  /** If the user is logged in. */
  LOGGED_IN = 'LOGGED_IN',
  /** If we have proven that the user is logged out. */
  LOGGED_OUT = 'LOGGED_OUT',
  /** If we have not checked the auth state. */
  UNCHECKED = 'UNCHECKED',
  /** If we have a saved auth token, we are checking if it's valid (to restore the user's auth state if they previously logged in). */
  CHECKING = 'CHECKING',
  /** If the user is logged in, but short code validation has not been done. */
  LOGGED_IN_NO_VALIDATION = 'LOGGED_IN_NO_VALIDATION',

}

enum AuthStorageKeys {
  account = 'ACCOUNT',
}

const storage = new ScopedStorage<AuthStorageKeys>('AuthVuexModule');

type AuthRequest = (() => LoginResponse) | (() => Promise<LoginResponse>);
type UserValidationResponseRequest = (() => UserValidationResponse) | (() => Promise<UserValidationResponse>);



@Module({
  dynamic: true,
  store,
  name: 'AuthVuexModule',
  namespaced: true,
})
export class AuthVuexModule extends VuexModule {
  // state variables
  authState: AuthStates = AuthStates.UNCHECKED;
  account: SingleUserDTO | null = null;
  private accessToken: string | null = null;

  // getters
  get isVAR() {
    return this.account?.groups.includes(UserGroups.VAR);
  }

  get isSuperAdmin() {
    return this.account?.groups.includes(UserGroups.SUPER_ADMIN);
  }

  get isCustomerAdmin() {
    return this.account?.groups.includes(UserGroups.CUSTOMER_ADMIN);
  }

  get isDeleted() {
    return this.account?.groups.includes(UserGroups.DELETED);
  }

  // actions
  @Action({ rawError: true })
  async validation(validationRequest: UserValidationResponseRequest) {
    const resp = await validationRequest();
    console.log(resp)
    if(resp.success) {
      this.SET_AUTH_STATE(AuthStates.LOGGED_IN);
    } else {
      alertError(
          null,
          'Failed to validate! Invalid Verification Code.',
      );
    }
  }

  @Action({ rawError: true })
  async login(authRequest: AuthRequest) {
    this.SET_AUTH_STATE(AuthStates.LOGGING_IN);
    try {
      const resp = await authRequest();
      console.log('auth:login:resp', resp)
      this.SET_ACCOUNT(resp.user);
      this.SET_ACCESS_TOKEN(resp.access_token);
      if(resp.require_validation) {
        this.SET_AUTH_STATE(AuthStates.LOGGED_IN_NO_VALIDATION);
      } else {
        this.SET_AUTH_STATE(AuthStates.LOGGED_IN);
      }

    } catch (error) {
      alertError(
        error,
        'Failed to login! Username or password may be incorrect.',
      );
      this.logout();
      this.SET_AUTH_STATE(AuthStates.LOGGED_OUT);
    }

    return this.account;
  }

  @Action({ rawError: true })
  logout() {
    console.log('auth:logout')
    this.SET_AUTH_STATE(AuthStates.LOGGED_OUT);
    this.CLEAR_ACCESS_TOKEN();
    this.CLEAR_ACCOUNT();
    window.localStorage.clear();
  }

  @Action({ rawError: true })
  async checkAuth() {
    console.log('auth:checkAuth')
    this.SET_AUTH_STATE(AuthStates.CHECKING);

    try {
      const _accessToken = await refreshAccessToken();
      const _account = storage.getItem(AuthStorageKeys.account);

      if (_account) {
        console.log('auth:User previously signed in; restoring auth state');
        this.SET_ACCESS_TOKEN(_accessToken);
        this.SET_ACCOUNT(JSON.parse(_account));
        this.SET_AUTH_STATE(AuthStates.LOGGED_IN);
      } else {
        this.logout();
      }
    } catch (err) {
      this.logout();
    }

    return this.account;
  }

  @Action({ rawError: true })
  async getAccessToken() {
    console.log('auth:getAccessToken', this.accessToken)
    if (this.accessToken === null) {
      // If the access token is null, we just return null for now
      return null;
    } else {
      // Else, we see if the token needs to be refreshed
      const tokenData = JSON.parse(atob(this.accessToken.split('.')[1]));
      const currTime = Math.floor(new Date().getTime() / 1000);
      const expTime = tokenData.exp;

      // (If we're within 60 seconds of expiration, we'll refresh the token)
      if (currTime >= expTime - 60) {
        const _accessToken = await refreshAccessToken();
        this.SET_ACCESS_TOKEN(_accessToken);
      }

      // Now that we have a valid token, we can return it
      return this.accessToken;
    }
  }

  @Action({ rawError: true })
  async reFetchUser() {
    if (this.account === null) {
      return;
    } else {
      const resp = await fetchSingleUser(this.account.id);

      this.SET_ACCOUNT(resp.user);
    }
  }

  // mutations

  @Mutation
  SET_ACCESS_TOKEN(_accessToken: string) {
    this.accessToken = _accessToken;
  }

  @Mutation
  CLEAR_ACCESS_TOKEN() {
    this.accessToken = null;
  }

  @Mutation
  SET_ACCOUNT(_account: SingleUserDTO) {
    this.account = _account;
    storage.setItem(AuthStorageKeys.account, JSON.stringify(_account));
  }

  @Mutation
  CLEAR_ACCOUNT() {
    this.account = null;
    storage.clear();
  }

  @Mutation
  SET_AUTH_STATE(_authState: AuthStates) {
    this.authState = _authState;
  }
}

export const AuthModule = getModule(AuthVuexModule);
