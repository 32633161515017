import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorView = _resolveComponent("ErrorView")
  const _component_GeotabSetupComp = _resolveComponent("GeotabSetupComp")
  const _component_AuthCheckView = _resolveComponent("AuthCheckView")

  return (_ctx.error)
    ? (_openBlock(), _createBlock(_component_ErrorView, {
        key: 0,
        message: _ctx.error
      }, null, 8, ["message"]))
    : (_ctx.isInGeotabSetupTestMode)
      ? (_openBlock(), _createBlock(_component_GeotabSetupComp, { key: 1 }))
      : (_openBlock(), _createBlock(_component_AuthCheckView, { key: 2 }))
}