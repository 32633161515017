import { DefaultResponse } from './dto/default-response';

export interface APIResponse<T> extends Response {
  data: T;
}

export interface APIError extends DefaultResponse {
  error: string;
  message: string[] | string;
}

export async function tryRequest<T>(
  fetchReq: Promise<Response>,
): Promise<APIResponse<T>> {
  try {
    const response = await fetchReq;
    return {
      data: await response.json(),
      ...response,
    };
  } catch (err) {
    console.error(err);
    throw err;
  }
}
