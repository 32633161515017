import {passwordAuthRequest, userValidation} from '@/api/accidentplan-api';
import { AuthModule } from '@/store/auth';
import { computed, ref } from 'vue';

export function useAuth() {
  const userIdText = ref('');
  const passwordText = ref('');
  const authState = computed(() => AuthModule.authState);
  const account = computed(() => AuthModule.account);
  const isCustomerAdmin = computed(() => AuthModule.isCustomerAdmin);
  const isVAR = computed(() => AuthModule.isVAR);
  const isSuperAdmin = computed(() => AuthModule.isSuperAdmin);
  const isDeleted = computed(() => AuthModule.isDeleted);
  const shortcodeText = ref('');

  const login = () =>
      AuthModule.login(() =>
          passwordAuthRequest(userIdText.value, passwordText.value),
      );

  const validation = () =>
      AuthModule.validation(() =>
          userValidation(shortcodeText.value),
      );

  const logout = () => AuthModule.logout();

  return {
    authState,
    account,
    isCustomerAdmin,
    isVAR,
    isSuperAdmin,
    isDeleted,
    userIdText,
    passwordText,
    login,
    logout,
    shortcodeText,
    validation
  };
}
