import { APIError } from '@/api';
import { ElMessage } from 'element-plus';

export function alertError(error: any, defaultMessage = 'An error occurred.') {
  const apiError = error as APIError;
  if (error.statusCode)
    ElMessage.error(
      `${
        Array.isArray(apiError.message)
          ? apiError.message.join(', ')
          : apiError.message
      }`,
    );
  else ElMessage.error(defaultMessage);
}
