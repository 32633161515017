
import { defineComponent } from 'vue';
import { AuthStates, AuthModule } from '@/store/auth';
import { useAuth } from '@/composables/useAuth';

export default defineComponent({
  name: 'LoginView',
  setup() {
    const { authState } = useAuth();

    const logout = () => AuthModule.logout();

    return { authState, AuthStates, logout };
  },
});
