export class ScopedStorage<Key extends string | number> {
  constructor(private _scope: string) {
    this._checkKey(
      _scope,
      'Error creating ScopedStorage: Please provide a valid scope.',
    );
  }

  private _checkKey(str: string, errMsg: string) {
    if (typeof str !== 'string' || str.length < 1 || str.includes('/'))
      throw new Error(errMsg);
  }

  private _genKey(key: Key) {
    if (typeof key === 'string')
      this._checkKey(key, 'Error creating key: Please provide a valid key.');
    return `${this._scope}/${key}`;
  }

  setItem(key: Key, value: string) {
    window.localStorage.setItem(this._genKey(key), value);
  }

  getItem(key: Key) {
    return window.localStorage.getItem(this._genKey(key));
  }

  removeItem(key: Key) {
    window.localStorage.removeItem(this._genKey(key));
  }

  clear() {
    for (let i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i);
      const keyScope = key?.split('/')[0];
      if (key !== null && keyScope === this._scope)
        window.localStorage.removeItem(key);
    }
  }
}
