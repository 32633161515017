<template>
  <el-container id="loading-app-view">
    <el-main>
      <div class="pane">
        <img src="@/assets/images/ap-logo.svg" style="max-width: 400px;" />
        <h3 style="text-align: center">Loading Portal...</h3>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoadingAppView',
});
</script>

<style lang="scss">
#loading-app-view {
  .el-header,
  .el-footer {
    background-color: #b3c0d1;
    color: #333;
    text-align: center;
    line-height: 60px;
  }

  .el-aside {
    background-color: #d3dce6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }

  .el-main {
    background-color: #636363;
    color: #333;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pane {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    // border: 1px solid lightgray;
    border-radius: 5px;
    transition: 0.3s;
    width: 400px;
    margin: 1rem auto;
    padding: 20px 16px;
    background: white;

    button {
      margin-bottom: 20px;
    }
  }
}
</style>
