
import {
  defineComponent,
  ref,
  computed,
  ComputedRef,
  defineAsyncComponent,
} from 'vue';
import AuthCheckView from '@/views/AuthCheck.vue';
import ErrorView from '@/views/Error.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    AuthCheckView,
    ErrorView,
    GeotabSetupComp: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "GeotabSetup" */ '@/components/geotab-setup/GeotabSetup.vue'
      ),
    ),
  },
  setup() {
    const error = ref('');
    const route = useRoute();

    // check for anything that would be fatal for our application before loading it

    if (!navigator.cookieEnabled)
      error.value = `It seems that cookies are disabled...<br>
      If this is embedded somewhere, you must enable 3rd party cookies for the app to function.`;

    // check if we're in the Geotab setup test mode
    const isInGeotabSetupTestMode: ComputedRef<boolean> = computed(
      () => route.query.geotab_setup_test_mode === 'true',
    );

    return { error, isInGeotabSetupTestMode };
  },
});
