import { SortDirection } from '@/helpers/data-query';
import { DefaultResponse } from './default-response';

export enum UserGroups {
  VAR = 'value-added-resaler',
  SUPER_ADMIN = 'admin',
  CUSTOMER_ADMIN = 'customer-admin',
  DELETED = 'deleted',
  DRIVER = 'driver',
}

export enum UserStatuses {
  ALL = 'all',
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export const UserGroupsHR: Record<UserGroups, string> = {
  [UserGroups.CUSTOMER_ADMIN]: 'Admin',
  [UserGroups.SUPER_ADMIN]: 'Super Admin',
  [UserGroups.VAR]: 'VAR',
  [UserGroups.DELETED]: 'TERMINATED',
  [UserGroups.DRIVER]: 'Driver',
};

export interface SingleUserDTO {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  last_visit: Date;
  created: Date;
  modified: Date;
  organizations_id: number;
  organization_name: string;
  user_id: string;
  phone_number: string;
  groups: UserGroups[];
  signin_provider: string;
  organization_groups: string[],
  org_groups: number[]
}

export interface SingleUserResponse extends DefaultResponse {
  user: SingleUserDTO;
}

export interface LoginResponse extends SingleUserResponse {
  access_token: string;
  refresh_token: string;
  require_validation: boolean;
}

export interface UserValidationResponse extends DefaultResponse {
  success: boolean;
}
// export interface LoginResponse extends SingleUserResponse {
//   access_token: string;
//   refresh_token: string;
// }

export interface UsersQueryItemDTO {
  id: number;
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  last_visit: string;
  created: string;
  organizations_id: number;
  organization_name: string;
  groups: UserGroups[];
}

export interface UsersQueryResponse extends DefaultResponse {
  query: {
    limit: number;
    offset: number;
    sort_by: keyof UsersQueryItemDTO;
    sort_dir: SortDirection;
    groups: UserGroups[];
    orgs: number[];
  };
  return_count: number;
  total: number;
  type: 'user';
  results: UsersQueryItemDTO[];
}

export interface CreateUserDTO {
  first_name?: string;
  last_name?: string;
  user_id: string;
  organizations_id?: number;
  email?: string;
  phone_number?: string;
  groups?: UserGroups[];
  password: string;
}

export interface UpdateUserDTO {
  id: number;
  first_name?: string;
  last_name?: string;
  user_id?: string;
  organizations_id?: number;
  email?: string;
  phone_number?: string;
  groups?: UserGroups[];
  password?: string;
}

export interface UserIDResponse {
  id: number;
  type: string;
}

export interface ManyUserIDResponse extends DefaultResponse {
  users: UserIDResponse[];
}

export interface ActiveUsersInOrgResponse extends DefaultResponse {
  count: number;
}
