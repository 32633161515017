import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingAppView = _resolveComponent("LoadingAppView")
  const _component_LoginView = _resolveComponent("LoginView")
  const _component_AccountTerminatedView = _resolveComponent("AccountTerminatedView")
  const _component_router_view = _resolveComponent("router-view")
  const _component_NotAdminView = _resolveComponent("NotAdminView")

  return (
      _ctx.authState === _ctx.AuthStates.UNCHECKED || _ctx.authState === _ctx.AuthStates.CHECKING
    )
    ? (_openBlock(), _createBlock(_component_LoadingAppView, { key: 0 }))
    : (
      _ctx.authState === _ctx.AuthStates.LOGGING_IN || _ctx.authState === _ctx.AuthStates.LOGGED_OUT || _ctx.authState === _ctx.AuthStates.LOGGED_IN_NO_VALIDATION
    )
      ? (_openBlock(), _createBlock(_component_LoginView, { key: 1 }))
      : (_ctx.authState === _ctx.AuthStates.LOGGED_IN && _ctx.isDeleted)
        ? (_openBlock(), _createBlock(_component_AccountTerminatedView, { key: 2 }))
        : (
      _ctx.authState === _ctx.AuthStates.LOGGED_IN &&
        (_ctx.isVAR || _ctx.isCustomerAdmin || _ctx.isSuperAdmin)
    )
          ? (_openBlock(), _createBlock(_component_router_view, { key: 3 }))
          : (_openBlock(), _createBlock(_component_NotAdminView, { key: 4 }))
}