import { defineAsyncComponent, markRaw } from 'vue';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'CrashFilesPage' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    children: [
      {
        path: '/users',
        name: 'UsersPage',

        component: () =>
          import(/* webpackChunkName: "UsersPage" */ '../pages/UsersPage.vue'),
      },
      {
        path: '/reports',
        name: 'ReportsPage',
        component: () =>
          import(
            /* webpackChunkName: "ReportsPage" */ '../pages/ReportsPage.vue'
          ),
      },
      {
        path: '/reports/:id',
        name: 'ReportPage',
        component: () =>
          import(
            /* webpackChunkName: "ReportPage" */ '../pages/ReportPage.vue'
          ),
      },
      {
        path: '/crashfiles',
        name: 'CrashFilesPage',
        component: () =>
          import(
            /* webpackChunkName: "CrashFilesPage" */ '../pages/CrashFilesPage.vue'
          ),
      },
      {
        path: '/crashfiles/:id',
        name: 'CrashFilePage',
        component: () =>
          import(
            /* webpackChunkName: "CrashFilePage" */ '../pages/CrashFilePage.vue'
          ),
      },
      {
        path: '/crashfiles/:id/print',
        name: 'CrashFilePagePrint',
        meta: { print: true },
        component: () => 
          markRaw(
            import(
              /* webpackChunkName: "CrashFilePagePrint" */ '../pages/CrashFilePagePrint.vue'
            )
          ),
      },
      {
        path: '/workflows',
        name: 'WorkflowsPage',
        component: () =>
          import(
            /* webpackChunkName: "WorkflowsPage" */ '../pages/WorkflowsPage.vue'
          ),
      },
      {
        path: '/workflows/:id',
        name: 'WorkflowEditPage',
        component: () =>
          import(
            /* webpackChunkName: "WorkflowPage" */ '../pages/EditWorkflow.vue'
          ),
      },
      {
        path: '/users/create',
        name: 'UserFormCreate',
        component: () =>
          import(
            /* webpackChunkName: "UserFormCreate" */ '../pages/CreateUserPage.vue'
          ),
      },
      {
        path: '/users/edit/:id',
        name: 'UserFormEdit',
        component: () =>
          import(
            /* webpackChunkName: "UserFormEdit" */ '../pages/UserPage.vue'
          ),
      },
      {
        path: '/organizations',
        name: 'OrganizationsPage',
        component: () =>
          import(
            /* webpackChunkName: "OrganizationsPage" */ '../pages/OrganizationsPage.vue'
          ),
      },
      {
        path: '/organizations/edit/:id',
        name: 'OrganizationsFormEdit',
        component: () =>
          import(
            /* webpackChunkName: "OrganizationPage" */ '../pages/OrganizationPage.vue'
          ),
      },
      {
        path: '/organizations/create',
        name: 'OrganizationsFormCreate',
        component: () =>
          import(
            /* webpackChunkName: "OrganizationsFormCreate" */ '../components/orgs/OrgForm.vue'
          ),
      },
      {
        path: '/profile',
        name: 'ProfilePage',
        component: () =>
          import(
            /* webpackChunkName: "ProfilePage" */ '../pages/ProfilePage.vue'
          ),
      },
      {
        path: '/profile/general',
        name: 'ProfilePageGeneral',
        component: () =>
          import(
            /* webpackChunkName: "ProfilePageGeneral" */ '../pages/ProfilePage.vue'
          ),
      },
      {
        path: '/profile/security',
        name: 'ProfilePageSecurity',
        component: () =>
          import(
            /* webpackChunkName: "ProfilePageSecurity" */ '../pages/ProfilePage.vue'
          ),
      },
      {
        path: '/profile/preferences',
        name: 'ProfilePagePreferences',
        component: () =>
          import(
            /* webpackChunkName: "ProfilePagePreferences" */ '../pages/ProfilePage.vue'
          ),
      },
      {
        path: '/global-config',
        name: 'GlobalConfigPage',
        component: () =>
          import(
            /* webpackChunkName: "GlobalConfigPage" */ '../pages/GlobalConfigPage.vue'
          ),
      },
      {
        path: '/global-config/report-builder',
        name: 'GlobalConfigPageReportBuilder',
        component: () =>
          import(
            /* webpackChunkName: "GlobalConfigPage" */ '../pages/GlobalConfigPage.vue'
          ),
      },
      {
        path: '/global-config/geotab',
        name: 'GlobalConfigPageGeotab',
        component: () =>
          import(
            /* webpackChunkName: "GlobalConfigPage" */ '../pages/GlobalConfigPage.vue'
          ),
      },
      {
        path: '/global-config/open-weather-map',
        name: 'GlobalConfigPageOpenWeatherMap',
        component: () =>
          import(
            /* webpackChunkName: "GlobalConfigPage" */ '../pages/GlobalConfigPage.vue'
          ),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
