/* eslint-disable */
import { SortDirection } from '@/helpers/data-query';
import { AuthModule } from '@/store/auth';
import { tryRequest } from '.';
import { RefreshTokenResponse } from './dto/auth.dto';
import {
  GetUserImportJobsResponse,
  ImportUsersResponse,
} from './dto/user-import.dto';
import {
  AddOrgsContactDTO,
  CreateSingleOrgDTO,
  OrganizationListResponse,
  OrgNameTakenResponse,
  OrgsContact,
  OrgsContactsAddResponse,
  OrgsContactsDeleteResponse,
  OrgsContactsQueryResponse,
  OrgsQueryResponse,
  SingleOrgResponse,
  SingleOrgGroupResponse,
  UpdateSingleOrgDTO,
  UpdateSingleOrgGroupDTO,
} from './dto/org';
import {
    ActiveUsersInOrgResponse,
    CreateUserDTO,
    LoginResponse,
    ManyUserIDResponse,
    SingleUserResponse,
    UpdateUserDTO,
    UserGroups,
    UsersQueryItemDTO,
    UsersQueryResponse, UserValidationResponse,
} from './dto/user';
import { UserPrefs, UserPrefsResponse } from './dto/user-prefs';
import { GeotabAuthDTO, GeotabSignupDTO } from './dto/geotab.dto';
import {
  RRTemplatesResponse,
  SearchRRTemplatesDTO,
  SingleRRTemplateResponse,
} from './dto/rr-template.dto';
import { API_BASE_URL } from './urls';
import { GlobalConfigSettingResponse } from './dto/global-config.dto';

export const baseURL = API_BASE_URL;

export async function passwordAuthRequest(user_id: string, password: string) {
  const resp =
    (
      await tryRequest<LoginResponse>(
        fetch(`${baseURL}/auth/login`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            user_id,
            password,
          }),
        }),
      )
    ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function userValidation(code: string) {
    const token = await AuthModule.getAccessToken();
    const user = await AuthModule.account;
    const resp =
        (
            await tryRequest<UserValidationResponse>(
                fetch(`${baseURL}/auth/userValidation?code=${code}`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        id: user?.id,
                        code
                    }),
                }),
            )
        ).data;

    if (resp.statusCode >= 400) throw resp;

    return resp;
}

export async function refreshAccessToken() {
  const resp = (
    await tryRequest<RefreshTokenResponse>(
      fetch(`${baseURL}/auth/refresh`, {
        method: 'POST',
        credentials: 'include',
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.access_token;
}

export async function fetchSingleOrg(id: number) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<SingleOrgResponse>(
      fetch(`${baseURL}/orgs/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.organization;
}

export async function fetchSingleOrgGroup(id: number) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<SingleOrgGroupResponse>(
      fetch(`${baseURL}/orgs/group/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.group;
}

export async function createSingleOrg(orgDto: CreateSingleOrgDTO) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<SingleOrgResponse>(
      fetch(`${baseURL}/orgs`, {
        method: 'POST',
        body: JSON.stringify(orgDto),
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.organization;
}

export async function updateSingleOrg(orgDto: UpdateSingleOrgDTO) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<SingleOrgResponse>(
      fetch(`${baseURL}/orgs`, {
        method: 'PATCH',
        body: JSON.stringify(orgDto),
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.organization;
}

export async function updateSingleOrgGroup(orgDto: UpdateSingleOrgGroupDTO) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<SingleOrgGroupResponse>(
      fetch(`${baseURL}/orgs/group`, {
        method: 'PATCH',
        body: JSON.stringify(orgDto),
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.group;
}

export async function getOrgContacts(orgId: number): Promise<OrgsContact[]> {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<OrgsContactsQueryResponse>(
      fetch(`${baseURL}/contact-options/?org=${orgId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.contact_options;
}

export async function addOrgContacts(
  orgId: number,
  contacts: AddOrgsContactDTO[],
) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<OrgsContactsAddResponse>(
      fetch(`${baseURL}/contact-options/?org=${orgId}`, {
        method: 'POST',
        body: JSON.stringify(contacts),
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.contact_options;
}

export async function deleteOrgContacts(contactIds: number[]) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<OrgsContactsDeleteResponse>(
      fetch(`${baseURL}/contact-options/?ids=${contactIds.join(',')}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.deleted;
}

/**
 * @function fetchManageableOrgs
 * Fetches a list of orgs that the currently signed
 * in user has permission to manage.
 */
export async function fetchManageableOrgs(userId: number) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<OrganizationListResponse>(
      fetch(`${baseURL}/orgs?userId=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.organizations;
}

export async function fetchOrgList(
  userId: number,
  sort_by: string,
  sort_dir: string,
  limit: number,
  offset: number,
  search?: string,
) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<OrgsQueryResponse>(
      fetch(
        `${baseURL}/orgs?userId=${userId}&query=true&limit=${limit}&offset=${offset}&sort_by=${sort_by}&sort_dir=${sort_dir}${
          search ? `&search=${search}` : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function isOrgNameTaken(name: string) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<OrgNameTakenResponse>(
      fetch(`${baseURL}/orgs/taken?name=${name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.is_name_taken;
}

export async function fetchUserList(
  orgIds: number[],
  limit: number,
  offset: number,
  sortBy: keyof UsersQueryItemDTO,
  sortDir: SortDirection,
  groups: UserGroups[],
  deleted: boolean | undefined,
  search: string,
) {
  // const resp = (
  //   await tryRequest<UsersQueryResponse>(
  //     fetch(`${baseUrl}/users?orgs=15,151,150,149,16,17`),
  //   )
  // ).data;

  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<UsersQueryResponse>(
      fetch(
        `${baseURL}/users?orgs=${orgIds.join(
          ',',
        )}&limit=${limit}&offset=${offset}&sort_dir=${sortDir}&sort_by=${sortBy}${
          groups.length > 0 ? `&groups=${groups.join(',')}` : ''
        }${deleted !== undefined ? `&deleted=${deleted}` : ''}${
          search.length > 0 ? `&search=${search}` : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function fetchSingleUser(id: number) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<SingleUserResponse>(
      fetch(`${baseURL}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function fetchActiveUserCountInOrg(orgId: number) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<ActiveUsersInOrgResponse>(
      fetch(`${baseURL}/users/active/?org_id=${orgId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.count;
}

export async function updateSingleUser(user: UpdateUserDTO) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<SingleUserResponse>(
      fetch(`${baseURL}/users`, {
        method: 'PATCH',
        body: JSON.stringify(user),
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function createSingleUser(user: CreateUserDTO) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<SingleUserResponse>(
      fetch(`${baseURL}/users`, {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function deleteUsers(userIds: number[]) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<ManyUserIDResponse>(
      fetch(`${baseURL}/users/?userIds=${userIds.join(',')}`, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function rehireUsers(userIds: number[]) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<ManyUserIDResponse>(
      fetch(`${baseURL}/users/rehire/?userIds=${userIds.join(',')}`, {
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function importUsers(csv: File, map: File) {
  const uploadData = new FormData();
  uploadData.append('csv', csv, 'users.csv');
  uploadData.append('map', map, 'map.json');

  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<ImportUsersResponse>(
      fetch(`${baseURL}/users/import/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: uploadData,
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function getUserImportJobs() {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<GetUserImportJobsResponse>(
      fetch(`${baseURL}/users/import`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function exportUsersByOrg(orgs: number[]): Promise<string> {
  const token = await AuthModule.getAccessToken();
  const resp = await fetch(
    `${baseURL}/export/users/?orgIds=${orgs.join(',')}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'text',
      },
    },
  ).catch(error => {
    throw error;
  });

  if (resp.status >= 400) throw resp;

  return await resp.text();
}

export async function exportUsersByQuery(
  orgIds: number[],
  sortBy: keyof UsersQueryItemDTO,
  sortDir: SortDirection,
  groups: UserGroups[],
  deleted: boolean | undefined,
  search: string,
) {
  const token = await AuthModule.getAccessToken();
  const resp = await fetch(
    `${baseURL}/export/users?orgs=${orgIds.join(
      ',',
    )}&sort_dir=${sortDir}&sort_by=${sortBy}${
      groups.length > 0 ? `&groups=${groups.join(',')}` : ''
    }${deleted !== undefined ? `&deleted=${deleted}` : ''}${
      search.length > 0 ? `&search=${search}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'text',
      },
    },
  ).catch(error => {
    throw error;
  });

  if (resp.status >= 400) throw resp;

  return await resp.text();
}

export async function exportOrgs(orgs: number[]): Promise<string> {
  const token = await AuthModule.getAccessToken();
  const resp = await fetch(`${baseURL}/export/orgs/?orgIds=${orgs.join(',')}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'text',
    },
  }).catch(error => {
    throw error;
  });

  if (resp.status >= 400) throw resp;

  return await resp.text();
}

export async function updateUserPrefs(userPrefs: UserPrefs) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<UserPrefsResponse>(
      fetch(`${baseURL}/users/prefs/`, {
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userPrefs),
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function setUserPrefs(userPrefs: UserPrefs) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<UserPrefsResponse>(
      fetch(`${baseURL}/users/prefs/`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userPrefs),
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function fetchUserPrefs() {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<UserPrefsResponse>(
      fetch(`${baseURL}/users/prefs/`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function getRRTemplate(id: number) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<SingleRRTemplateResponse>(
      fetch(`${baseURL}/rr-templates/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.template;
}

export async function searchRRTemplates(queryDTO: SearchRRTemplatesDTO) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<RRTemplatesResponse>(
      fetch(
        `${baseURL}/rr-templates/?
        ${queryDTO.name_query ? `name_query=${queryDTO.name_query}` : ''}
      `,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.templates;
}

export async function geotabLoginRequest(credentials: GeotabAuthDTO) {
  const resp = (
    await tryRequest<LoginResponse>(
      fetch(`${baseURL}/auth/login/geotab`, {
        method: 'POST',
        body: JSON.stringify(credentials),
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp;
}

export async function signupWithGeotab(signupDTO: GeotabSignupDTO) {
  const response = (
    await tryRequest<any>(
      fetch(`${baseURL}/integrations/geotab/config/signup`, {
        method: 'POST',
        body: JSON.stringify(signupDTO),
        headers: {
          'content-type': 'application/json',
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response;
}

export async function getGlobalConfig<SettingType>(settingKey: string) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<GlobalConfigSettingResponse<SettingType>>(
      fetch(`${baseURL}/global-config/${settingKey}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.setting;
}

export async function setGlobalConfig<SettingType>(
  settingKey: string,
  newSetting: SettingType,
) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<GlobalConfigSettingResponse<SettingType>>(
      fetch(`${baseURL}/global-config/${settingKey}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(newSetting),
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.setting;
}
