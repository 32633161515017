import { fetchManageableOrgs } from '@/api/accidentplan-api';
import { SingleOrgSummaryDTO } from '@/api/dto/org';
import { FetchStates } from '@/helpers/fetch-states';
import { ScopedStorage } from '@/helpers/scoped-localstorage';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '.';
import { AuthModule, AuthStates } from './auth';

enum OrganizationsStorageKeys {
  orgs = 'ORGS',
  activeOrgIds = 'ACTIVE_ORG_IDS',
}

const storage = new ScopedStorage<OrganizationsStorageKeys>(
  'OrganizationsVuexModule',
);

@Module({
  dynamic: true,
  namespaced: true,
  name: 'OrganizationsVuexModule',
  store,
})
export class OrganizationsVuexModule extends VuexModule {
  // state

  /** If the logged in user is a VAR or super admin,
  they have access to multiple organizations.
  This is a map of organization ids -> organization names. */
  orgMap: Record<number, string> = {};

  /** The organizations that a user is working with at a given time. */
  activeOrgIds: number[] = [];

  /** The current fetch state of the org data. */
  fetchState: FetchStates = FetchStates.UNFETCHED;

  // getters

  get orgs(): SingleOrgSummaryDTO[] {
    return Object.keys(this.orgMap).map(idStr => {
      const id = parseInt(idStr, 10);
      return {
        id,
        name: this.orgMap[id],
      };
    });
  }

  get activeOrgs(): SingleOrgSummaryDTO[] {
    return this.activeOrgIds.map(id => ({
      id,
      name: this.orgMap[id],
    }));
  }

  @Action({ rawError: true })
  async fetchOrgs() {
    this.SET_FETCH_STATE(FetchStates.FETCHING);

    if (
      AuthModule.authState === AuthStates.LOGGED_IN &&
      (AuthModule.isVAR || AuthModule.isSuperAdmin)
    ) {
      const orgsFromLocal = storage.getItem(OrganizationsStorageKeys.orgs);
      const _orgs = orgsFromLocal
        ? JSON.parse(orgsFromLocal)
        : await fetchManageableOrgs(AuthModule.account!.id);
      this.SET_ORGS(_orgs);
    }

    const _activeOrgIds = storage.getItem(
      OrganizationsStorageKeys.activeOrgIds,
    );
    if (_activeOrgIds) this.SET_ACTIVE_ORG_IDS(JSON.parse(_activeOrgIds));
    else if (AuthModule.account)
      this.SET_ACTIVE_ORG_IDS([AuthModule.account.organizations_id]);

    this.SET_FETCH_STATE(FetchStates.FETCHED);
  }

  @Action({ rawError: true })
  async reFetchOrgs() {
    storage.removeItem(OrganizationsStorageKeys.orgs);

    await this.fetchOrgs();
  }

  // mutations

  @Mutation
  SET_ACTIVE_ORG_IDS(ids: number[]) {
    this.activeOrgIds = ids;
    storage.setItem(OrganizationsStorageKeys.activeOrgIds, JSON.stringify(ids));
  }

  @Mutation
  SET_ALL_ACTIVE_ORGS() {
    this.activeOrgIds = Object.keys(this.orgMap).map(id => parseInt(id, 10));
    storage.setItem(
      OrganizationsStorageKeys.activeOrgIds,
      JSON.stringify(this.activeOrgIds),
    );
  }

  @Mutation
  CLEAR_ACTIVE_ORGS() {
    this.activeOrgIds = [];
    storage.removeItem(OrganizationsStorageKeys.activeOrgIds);
  }

  @Mutation
  SET_ORGS(_orgs: SingleOrgSummaryDTO[]) {
    _orgs.forEach(org => (this.orgMap[org.id] = org.name));
    storage.setItem(OrganizationsStorageKeys.orgs, JSON.stringify(_orgs));
  }

  @Mutation
  CLEAR_ORGS() {
    this.activeOrgIds = [];
    this.orgMap = {};
    this.fetchState = FetchStates.UNFETCHED;
    storage.clear();
  }

  @Mutation
  SET_FETCH_STATE(_fetchState: FetchStates) {
    this.fetchState = _fetchState;
  }
}

export const OrganizationsModule = getModule(OrganizationsVuexModule);
