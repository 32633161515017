const DEV_API_BASE_URL = 'https://devapiv2.accidentplan.com';
const PROD_API_BASE_URL = 'https://apiv2.accidentplan.com';

const DEV_RR_BASE_URL = 'https://dev-roadready.accidentplan.com';
const PROD_RR_BASE_URL = 'https://roadready.accidentplan.com';

export let API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export let RR_BASE_URL = process.env.VUE_APP_RR_BASE_URL;

// override the env variable if we know we're in production
console.log('Accident Plan:host', window.location.host);
if (
  window.location.host === 'portalv2.accidentplan.com' ||
  window.location.host === 'portalv2-prod.accidentplan.com' ||
  window.location.host.indexOf('geotab.com') > 0
) {
  API_BASE_URL = PROD_API_BASE_URL;
  RR_BASE_URL = PROD_RR_BASE_URL;
}

// override the env variable if we know we're in cloud dev
if (window.location.host === 'devportalv2.accidentplan.com') {
  API_BASE_URL = DEV_API_BASE_URL;
  RR_BASE_URL = DEV_RR_BASE_URL;
}
