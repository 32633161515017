
import { defineComponent, watch, ref } from 'vue';
import { AuthModule, AuthStates } from '../store/auth';
import { useAuth } from '../composables/useAuth';
import LoadingAppView from './LoadingApp.vue';
import { OrganizationsModule } from '../store/organizations';
import AccountTerminatedView from './AccountTerminated.vue';
import LoginView from './Login.vue';
import NotAdminView from './NotAdmin.vue';

// const AccountTerminatedView = defineAsyncComponent(() =>
//   import(
//     /* webpackChunkName: "AccountTerminatedView" */ '@/views/AccountTerminated.vue'
//   ),
// );

// const LoginView = defineAsyncComponent(() =>
//   import(/* webpackChunkName: "LoginView" */ '@/views/Login.vue'),
// );

// const NotAdminView = defineAsyncComponent(() =>
//   import(/* webpackChunkName: "NotAdminView" */ '@/views/NotAdmin.vue'),
// );

export default defineComponent({
  components: {
    LoadingAppView,
    LoginView,
    NotAdminView,
    AccountTerminatedView,
  },
  setup() {
    AuthModule.checkAuth();

    const auth = useAuth();

    watch(auth.authState, state => {
      console.log('state', state);
      if (state === AuthStates.LOGGED_IN_NO_VALIDATION) OrganizationsModule.fetchOrgs();
      if (state === AuthStates.LOGGED_IN) OrganizationsModule.fetchOrgs();
      if (state === AuthStates.LOGGED_OUT) OrganizationsModule.CLEAR_ORGS();
    });

    return {
      AuthStates,
      ...auth,
    };
  },
});
